<template>
  <div>
    <div class="min-vh-100 position-relative">
      <CRow class="no-gutters px-3 px-sm-0">
        <b-col xl="4" class="text-center text-sm-left mb-3 mb-sm-0">
          <h1 class="mr-sm-4 header-main text-uppercase">แบนเนอร์เซ็คชั่น</h1>
        </b-col>
        <b-col xl="8" class="text-right">
          <div class="d-flex justify-content-end">
            <b-input-group class="panel-input-serach">
              <b-form-input
                class="input-serach"
                placeholder="ค้นหาชื่อแบนเนอร์"
                v-model="filter.search"
                @keyup="handleSearch"
              ></b-form-input>
              <b-input-group-prepend @click="btnSearch">
                <span class="icon-input m-auto pr-2">
                  <font-awesome-icon icon="search" title="View" />
                </span>
              </b-input-group-prepend>
            </b-input-group>
            <router-link to="/section-banner/details/0">
              <b-button class="btn-main">สร้างแบนเนอร์</b-button>
            </router-link>
          </div>
        </b-col>
      </CRow>
      <div class="mt-3 bg-white px-0 pb-3">
        <b-row class="no-gutters">
          <b-col>
            <b-table
              striped
              responsive
              hover
              :items="items"
              :fields="fields"
              :busy="isBusy"
              show-empty
              empty-text="ไม่พบข้อมูล"
              class="table-list"
            >
              <!-- <template v-slot:cell(imageUrl)="data">
                <div
                  class="thumbnail-table ratio-10-2-pb"
                  v-bind:style="{
                    'background-image': 'url(' + data.item.imageUrl + ')',
                  }"
                  v-if="data.item.isVideo != true"
                ></div>
                <div
                  v-else
                  class="position-relative p-0 embed-responsive embed-ratio-10-2-pb"
                >
                  <video class="w-100 videos" controls>
                    <source :src="data.item.imageUrl" type="video/mp4" />
                  </video>
                </div>
              </template> -->
              <template v-slot:cell(updatedTime)="data">
                <span>{{
                  new Date(data.item.updatedTime) | moment($formatDate)
                }}</span>
              </template>
              <template v-slot:cell(enabled)="data">
                <div v-if="data.item.enabled == true" class="text-success">
                  ใช้งาน
                </div>
                <div v-else class="text-danger">ไม่ใช้งาน</div>
              </template>
              <template v-slot:cell(id)="data">
                <div class="d-flex justify-content-center">
                  <router-link
                      :to="'/section-banner/details/' + data.item.id"
                    >
                      <b-button variant="link" class="text-dark px-1 py-0">
                        แก้ไข
                      </b-button>
                    </router-link>
                  <b-button
                    variant="link"
                    class="text-dark px-1 py-0"
                    @click="openModalDelete(data.item)"
                  >
                    ลบ
                  </b-button>
                </div>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row class="no-gutters px-3 px-sm-0">
          <b-col
            class="form-inline justify-content-center justify-content-sm-between"
          >
            <div class="d-sm-flex m-3">
              <b-pagination
                v-model="filter.pageNo"
                :total-rows="rows"
                :per-page="filter.perPage"
                class="m-md-0"
                @change="pagination"
                align="center"
              ></b-pagination>
            </div>
            <b-form-select
              class="mr-sm-3 select-page"
              v-model="filter.perPage"
              @change="hanndleChangeperPage"
              :options="pageOptions"
            ></b-form-select>
          </b-col>
        </b-row>
      </div>
    </div>
    <ModalBanner ref="modalBanner" />
    <ModalAlert ref="modalAlert" :text="modalMessage" />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
    <ModalAlertConfirm
      msg="ยืนยันการลบ ?"
      :text="modalMessage"
      btnConfirm="ลบ"
      colorBtnConfirm="danger"
      btnCancel="ยกเลิก"
      ref="ModalAlertConfirm"
      @confirm="btnDelete"
    />
  </div>
</template>

<script>
import ModalAlert from "@/components/modal/alert/ModalAlert";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
import ModalAlertConfirm from "@/components/modal/alert/ModalAlertConfirm";
import ModalBanner from "./ModalBanner.vue";
import InputText from "@/components/inputs/InputText";

export default {
  name: "SectionBanner",
  components: {
    ModalAlert,
    ModalAlertError,
    ModalAlertConfirm,
    ModalBanner,
    InputText,
  },
  data() {
    return {
      statusList: [],
      modalMessage: "",
      activeItem: "",
      requestDeleteBlog: {
        id: null,
      },
      fields: [
        // {
        //   key: "imageUrl",
        //   label: "ภาพประกอบ",
        //   class: "w-200",
        // },
        {
          key: "name",
          label: "ชื่อ",
          class: "w-200",
        },
        // {
        //   key: "sortOrder",
        //   label: "ลำดับการแสดง",
        //   class: "w-200",
        // },
        {
          key: "updatedTime",
          label: "วันที่อัพเดท",
          class: "w-100px",
        },
        {
          key: "enabled",
          label: "สถานะการแสดง",
          class: "w-100px",
        },
        {
          key: "id",
          label: "",
          class: "w-100px",
        },
      ],
      items: [],
      isBusy: false,
      rows: 0,
      filter: {
        search: "",
        perPage: 10,
        pageNo: 1,
      },
      pageOptions: [
        { value: 10, text: "10 / หน้า" },
        { value: 30, text: "30 / หน้า" },
        { value: 50, text: "50 / หน้า" },
        { value: 100, text: "100 / หน้า" },
      ],
      form: {},
      totalRowMessage: "",
      isDisable: false,
      timer: null,
    };
  },
  created: async function () {
    this.$isLoading = false;
    await this.getList();
  },
  methods: {
    getList: async function () {
      this.isBusy = true;
      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/CustomSection/List`,
        null,
        this.$headers,
        this.filter
      );
      if (resData.result == 1) {
        this.items = resData.detail.dataList;
        this.rows = resData.detail.count;
        this.isBusy = false;
        this.$isLoading = true;
      }
    },
    pagination(Page) {
      this.filter.pageNo = Page;
      this.getList();
    },
    hanndleChangeperPage(value) {
      this.filter.pageNo = 1;
      this.filter.perPage = value;
      this.getList();
    },
    handleSearch(e) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.filter.pageNo = 1;
        this.getList();
      }, 800);
    },
    btnSearch() {
      this.filter.pageNo = 1;
      this.getList();
    },
    openModal() {
      this.$refs.modalBanner.show();
    },
    openModalDelete(value) {
      this.requestDeleteBlog.id = value.id;
      this.modalMessage = "คุณต้องการลบ " + value.name + " ใช่หรือไม่?";
      this.$refs.ModalAlertConfirm.show();
    },
    btnDelete: async function () {
      this.$refs.ModalAlertConfirm.hide();
      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/CustomSection/Delete/${this.requestDeleteBlog.id}`,
        null,
        this.$headers,
        null
      );
      this.modalMessage = resData.message;
      if (resData.result == 1) {
        this.$refs.modalAlert.show();
        setTimeout(() => {
          this.$refs.modalAlert.hide();
        }, 3000);
        this.filter.Page = 1;
        await this.getList();
      } else {
        this.$refs.modalAlertError.show();
      }
    },
  },
};
</script>

<style scoped>
label {
  font-weight: bold;
}

.panel-input-serach {
  border: 1px solid #bcbcbc !important;
}
</style>
